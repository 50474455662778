import React from 'react';
import { graphql, Link } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faCogs, faLink } from '@fortawesome/free-solid-svg-icons'

import Layout from '../components/layout';
import { Row, Col } from 'reactstrap';
import Img from 'gatsby-image'

export default function Template({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  return (
    <Layout>
      <div className="py-5">
        <h1><Link to="/works"><FontAwesomeIcon icon={faChevronLeft} size="xs" /></Link> { frontmatter.title }</h1>
        <Row className="mt-5">
          <Col md="8" className="py-2">
            <Img fluid={frontmatter.cover.childImageSharp.fluid} className="my-2 mx-auto" style={{ maxWidth: '100%', maxHeight: '300px' }} />
            <div dangerouslySetInnerHTML={{ __html: html }}/>
          </Col>
          <Col md="4 border-left py-2">
            {frontmatter.links && (
              <div className="mb-5">
                <h4>Links <FontAwesomeIcon icon={faLink} size="xs" /></h4>
                <div>
                  {frontmatter.links.map(link => <div key={link.label}><a href={link.link} target="_blank" rel="noreferrer noopener">{link.label}</a></div>)}
                </div>
              </div>
            )}
            <h4>Tools <FontAwesomeIcon icon={faCogs} size="xs" /></h4>
            <div>
              {frontmatter.technologies.map(tech => <div key={tech}>{tech}</div>)}
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        role
        technologies
        cover {
            childImageSharp{
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        links {
          label
          link
        }
      }
    }
  }
`
